// 전체 폰트 조정
// * : font-size : 14px => 13px
:root {
  --label-width: 5rem;
  --dropdownForm-width : 10.5rem;
  --searchBtn-width : 10.5rem;
  --grid-gap-default : 0.7rem;
}
* {
  font-size: 14px;
}

// 폰트조정으로 인한 radio 버튼 위치 조정
html:not([dir=rtl]) .custom-control-label::before {
  left: -1.49rem;
}


// table padding 조정
// td, th : 0.75rem => 0.5rem
.table th {
  background-color: #F4F4F4;
}

.table th, .table td {
  padding: 0.75rem;
}

// CCardBody padding 조정
// .card-body : 1.25rem => 0.7rem
.card-body {
  padding: 0.7rem;
}

// CModalBody padding조정
// .modal-body : 1rem => 0 0.5rem
.modal-body {
  padding: 0 0.5rem
}


.modal-header .close {
  padding: 1.3rem 1.3rem 1rem 1rem;
}

.map-height {
  @media screen and (max-width: 768px){
    height: 450px;
  }
}
