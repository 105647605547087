// Variable overrides
button {
  //max-width: 200px;
  white-space: nowrap;
  @media screen and (max-width: 575px) {
    //max-width: 150px;
  }
}

$primary: #065EE2;
$dark: #2E3539;
$btn-disabled-opacity: .35;


// 저장하는 액션을 하는 버튼
// accept-btn

// 취소
// cancel-btn

// 삭제
// delete-btn

// 수정하기
// modify-btn


