.c-sidebar {
  background-color: #2f353a;
}

.c-sidebar .c-sidebar-brand {
  background: #fff;
}

.c-sidebar-nav-link.active {
  background: #30343b;
}