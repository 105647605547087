.nav-tabs {
  .nav-link {
    font-weight: bold;
    color: #000;
    background-color: rgb(48, 53, 57, .1);
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    @include themes($nav-theme-map, $create: parent) {
      color: #fff;
      background-color: $dark;
      border-color: $dark;
    }
  }
}