// Here you can add other styles
// Common
@font-face {
  font-family: 'Pretendard';
  src: url(../assets/font/PretendardVariable.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap");
}

* {
  font-family: 'Pretendard', sans-serif;
  font-variant-numeric: tabular-nums;
}

//input::-webkit-input-placeholder {
//  color: #ccc;
//}
//
//input:-ms-input-placeholder {
//  color: #ccc;
//}

label {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin-bottom: 0;
}

.white-space {
  white-space: pre-wrap;
}

.cursor {
  cursor: move;
}

.form-control::placeholder {
  color: #c2c2c2;
}

.mouse-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.only-mouse-hover {
  cursor: pointer;
}

.mouse-disable:hover {
  cursor: not-allowed;
}


// color
.text-gray {
  color: #757575;
}

.text-null {
  color: #BDBDBD;
}

.text-black {
  color: black;
}

.text-required {
  color: #FF1744;
}

// footer styles
.c-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  background-color: white;
}

address {
  margin-bottom: 10px;
}

.copyright {
  font-size: 1rem;
}

// Loading
.renderLoading {
  //background-color: rgba( 255, 255, 255, 0.5 );
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

// 반응형 Web 대응
// min- 이 크기 부터 // max- 이 크기까지
@media screen and (min-width: 992px) {
  // 992px ~
  .MemberListDetail > .modal-lg {
    max-width: 700px;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  // 576px ~ 992px
  .MemberListDetail > .modal-dialog {
    max-width: 600px;
  }
}

// switch
.c-switch {
  display: inline-block;
  width: 50px; // 40px
  height: 26px;
}

.c-switch-input:checked ~ .c-switch-slider::before {
  transform: translateX(25px); // 14px
}

.c-switch[class*="-3d"] .c-switch-slider::before {
  //top: -1px;
  //left: -1px;
  //width: 26px;
  //height: 26px;
  border: 0;
  border-radius: 50em;
  box-shadow: 0 2px 5px rgba(0, 0, 21, 0.3);
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
}

.c-switch[class*="-3d"].c-switch-lg .c-switch-input:checked ~ .c-switch-slider::before {
  transform: translateX(18px); //18px
}


// dialog index
.dialog-0 .modal-backdrop {
  z-index: 1040;
}

.dialog-0 .modal {
  z-index: 1041;
}

.dialog-1 .modal-backdrop {
  z-index: 1042;
}

.dialog-1 .modal {
  z-index: 1043;
}

.dialog-2 .modal-backdrop {
  z-index: 1044;
}

.dialog-2 .modal {
  z-index: 1045;
}

.dialog-3 .modal-backdrop {
  z-index: 1046;
}

.dialog-3 .modal {
  z-index: 1047;
}

.dialog-4 .modal-backdrop {
  z-index: 1048;
}

.dialog-4 .modal {
  z-index: 1049;
}

.centerPosition {
  position: absolute;
  left: 45%;
  top: 45%;
}

.tax-center {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

// 공지사항 안 이미지 설정
.noticeDetail img {
  width: 100%;
}

// 이용메뉴얼 위치
@keyframes transLeftArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.left-arrow {
  top: 50%;
  left: -2%;
  opacity: 0.4;
  transition: 0.5s;
}

.left-arrow:hover {
  animation: transLeftArrow 1s linear infinite;
}

@keyframes transRightArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.right-arrow {
  top: 50%;
  right: -2%;
  opacity: 0.4;
  transition: 0.5s;
}

.right-arrow:hover {
  animation: transRightArrow 1s linear infinite;
}

// breadcrumb 색상 변경
.c-legacy-theme .breadcrumb-item a {
  color: #73818f;
}

.c-legacy-theme .breadcrumb-item.active {
  color: #20a8d8;
}

.resizeable {
  resize: both;
  overflow: auto;
}

.break-all {
  word-break: break-all;
}

.border-light-gray {
  border-color: #e4e7ea;
}

// 테이블에서 메뉴 선택시
.c-legacy-theme .table tbody tr:focus {
  color: #fff;
  background-color: #20a8d8;
}

// 사이드바 길어지면 옆으로 가는 이슈 해결
.c-sidebar-nav {
  flex-wrap: nowrap
}

.rowClickableTable tbody {
  cursor: pointer;
}

.btn-block {
  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: 0;
  }
}

.gap-30 {
  gap: 0 30px;
}

.gap-10 {
  gap: 0 10px;
}

// nav-bar 색상원래 나오게
.nav-tabs .nav-link {
  border-color: #e4e7ea #e4e7ea #c8ced3;
}

.table-point {
  background-color: yellow;
}


// 지도 label 관련
.marker-position {
  position: relative;
  bottom: 6px;
  left: 0;
}

.gm-ui-hover-effect:focus {
  outline: none;
}

.infoWindowContent {
  list-style-type: none;
  margin-bottom: 0;
  padding-inline-start: 0;
}


// ----------------------------------
// 통계 - API 이력 table style
// ----------------------------------
.table_statistics_api {
  .api_domain {
    width: 220px;
  }
  .api_uri {
    width: 550px;
  }
}

.drivingHistory {
  .driveHistory_address {
    min-width: 500px;
    white-space: normal;
  }
}
